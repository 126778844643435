/*var acdefence = acdefence || {};
( function () {
    'use strict';
    jQuery( function () {
        // Typed text animation
        var typed = new Typed("#typed", {
            stringsElement: '#typed-strings',
            typeSpeed: 65,
            backSpeed: 0,
            backDelay: 500,
            startDelay: 200,
            loop: false
        });
    } );
}() );*/
